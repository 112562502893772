import headerProfileLogo from "../../assets/images/logo/header-profile-logo.png";
import "./header.scss";

const Header = () => {
  return (
    <div className="header">
      <div className="header-wrapper">
        {/* <div className="headerIcon">
          <BsArrowLeft />
        </div> */}
        <div className="headerPageProfile">
          <div className="profile-img">
            <img src={headerProfileLogo} alt="Logo" />
          </div>
          <div className="title">
            <p className="pageName">Glenfiddich Guide</p>
            {/* <p className="chatCategory">Business chat</p> */}
          </div>
        </div>
        {/* <div className="headerIcon">
          <BiSolidInfoCircle />{" "}
        </div> */}
      </div>
    </div>
  );
};

export default Header;
