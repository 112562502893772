import chatProfileLogo from "../../assets/images/logo/chat-profile-logo.png";
import "./profile.scss";

const Profile = () => {
  return (
    <div className="PageProfile">
      <div className="profile-img">
        {" "}
        <img src={chatProfileLogo} alt="Logo" />
      </div>
      <div className="title">
        <p className="pageName">Glenfiddich Guide</p>
        <p className="people-count">
          {/* <span>0</span> people like this{" "} */}
        </p>
        {/* <p className="chatCategory">Business type</p> */}
        {/* <a href="#" className="profileBtn">
          View Profile
        </a> */}
      </div>
    </div>
  );
};

export default Profile;
