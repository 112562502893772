import { ReactNode } from "react";
import headerProfileLogo from "../../assets/images/logo/header-profile-logo.png";

const IncomingMessage = ({
  isRecommendation,
  isQuestion,
  children,
}: {
  isRecommendation?: boolean;
  isQuestion: boolean;
  children: ReactNode;
}) => {
  return (
    <div
      className={`chatMsg reciver ${isQuestion ? "question" : ""} ${
        isRecommendation ? "recomendation" : ""
      }`}
    >
      {children}
      <div className="userProf">
        <img src={headerProfileLogo} alt="Logo" />
      </div>
    </div>
  );
};

export default IncomingMessage;
