import React, { useState, useEffect } from "react";
import Select from "react-select";
import { countries } from "countries-list";
import { Country } from "./landing-page-interface";
import logotxt from "../../assets/images/logo/logotxt.png";
import "./landing-page.scss";
import authenticateUser from "../api/api";

interface Props {
  onAgeSubmit: (age: number) => void;
}

interface AgeOption {
  value: string;
  label: string;
}

const LandingPage = ({ onAgeSubmit }: Props): JSX.Element => {
  const [token, setToken] = useState<string | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>({
    code: "US",
    name: "United States",
    value: "US",
    label: "United States",
  });

  useEffect(() => {
    const getToken = async () => {
      const token = await authenticateUser();
      setToken(token);
    };
    getToken();
  }, []);

  const [dateOfBirth, setDateOfBirth] = useState<{
    day: string;
    month: string;
    year: string;
  }>({ day: "", month: "", year: "" });
  //add day options

  // const [consent, setConsent] = useState(false);
  const currentYear = new Date().getFullYear();

  const ageOptions: AgeOption[] = Array.from({ length: 100 }, (_, i) => {
    const year = currentYear - i;
    return { value: String(year), label: String(year) };
  });

  const countryOptions: Country[] = Object.entries(countries)
    .map(([code, countryDetail]) => ({
      code,
      name: countryDetail.name,
      value: code,
      label: countryDetail.name,
    }))
    .sort((a, b) => a.name.localeCompare(b.name)); // sort by country name

  const handleCountryChange = (selectedOption: Country | null) => {
    setSelectedCountry(selectedOption);
  };

  const handleDayChange = (selectedOption: AgeOption | null) => {
    setDateOfBirth((prev) => ({ ...prev, day: selectedOption?.value || "" }));
  };

  const [dayOptions, setDayOptions] = useState<AgeOption[]>(() => {
    const daysInMonth = new Date(
      Number(dateOfBirth.year),
      Number(dateOfBirth.month),
      0
    ).getDate();
    return Array.from({ length: daysInMonth }, (_, i) => ({
      value: String(i + 1),
      label: String(i + 1),
    }));
  });

  const handleMonthChange = (selectedOption: AgeOption | null) => {
    const month = selectedOption?.value || "";
    let daysInMonth = 31;
    if (month === "2") {
      const year = Number(dateOfBirth.year);
      daysInMonth =
        year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0) ? 29 : 28;
    } else if (["4", "6", "9", "11"].includes(month)) {
      daysInMonth = 30;
    }
    const newDayOptions = Array.from({ length: daysInMonth }, (_, i) => ({
      value: String(i + 1),
      label: String(i + 1),
    }));
    const currentDay = Number(dateOfBirth.day);
    const isValidDay = newDayOptions.some(
      (option) => option.value === String(currentDay)
    );
    setDateOfBirth((prev) => ({
      ...prev,
      month,
      day: isValidDay ? String(currentDay) : newDayOptions[0]?.value || "",
    }));
    setDayOptions(newDayOptions);
  };
  const handleYearChange = (selectedOption: AgeOption | null) => {
    setDateOfBirth((prev) => ({ ...prev, year: selectedOption?.value || "" }));
  };

  // const handleConsentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setConsent(event.target.checked);
  //   event.target.setCustomValidity(event.target.required && !event.target.checked ? 'Please consent to the terms and conditions.' : '');
  // };

  function convertDateForIos(date: string) {
    var myDateArray = date.split("-");
    var theDate = new Date(
      Number(myDateArray[0]),
      Number(myDateArray[1]) - 1,
      Number(myDateArray[2])
    );
    return theDate;
  }

  function createAge(dateOfBirth: string) {
    // Convert the date of birth to a JavaScript Date object
    const dob = convertDateForIos(dateOfBirth);

    // Get the current date
    const currentDate = new Date();

    // Calculate the age
    let currentAge = currentDate.getFullYear() - dob.getFullYear();

    // Check if the birthday has already occurred this year
    if (
      currentDate.getMonth() < dob.getMonth() ||
      (currentDate.getMonth() === dob.getMonth() &&
        currentDate.getDate() < dob.getDate())
    ) {
      currentAge--;
    }

    return currentAge;
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { day, month, year } = dateOfBirth;
    // const selectedDate = new Date(`${year}-${month}-${day}`);
    // const ageDiffMs = Date.now() - selectedDate.getTime();
    // const ageDate = new Date(ageDiffMs);
    const age = createAge(`${year}-${month}-${day}`);
    const { code, name } = selectedCountry || { code: "", name: "" };
    onAgeSubmit(age);

    localStorage.setItem("birthdate", `${year}-${month}-${day}`);
    localStorage.setItem("country", name);

    const payload = {
      userid: "",
      firstname: "",
      lastname: "",
      emailid: "",
      country: name,
      birthdate: `${year}-${month}-${day}`,
      isguest: "false",
    };
    try {
      const backendapi = process.env.REACT_APP_BACKEND_API_URL;
      const response = await fetch(`${backendapi}createGuest`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        const data = await response.json();
        const userid = data.data[0].createuser;
        // Store the createuser value to the local storage
        localStorage.setItem("userid", userid);
        // Pass the createuser value to the onGuestCreated function
      } else {
        throw new Error("API request failed");
      }
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };
  return (
    <div className="landing-page">
      <div className="welcome-wrapper">
        <h1>
          WELCOME <span>TO THE</span> GLENFIDDICH GUIDE
        </h1>
        <p className="subtext">
          WORLD&rsquo;S MOST AWARDED SINGLE MALT SCOTCH WHISKY*
        </p>
        <div className="txt-logo-wrapper">
          <img src={logotxt} alt="logotxt" />
        </div>
      </div>
      <form onSubmit={handleSubmit} className=" form">
        <label htmlFor="day">ENTER DATE OF BIRTH:</label>
        <div className="select-container">
          <Select
            menuPlacement="auto"
            className="selectField"
            classNamePrefix="custom-select"
            id="month"
            value={ageOptions.find(
              (option) => option.value === dateOfBirth.month
            )}
            onChange={handleMonthChange}
            options={Array.from({ length: 12 }, (_, i) => ({
              value: String(i + 1),
              label: String(i + 1),
            }))}
            placeholder="MM"
            required
            isSearchable={false}
          />
          <Select
            menuPlacement="auto"
            className="selectField"
            classNamePrefix="custom-select"
            id="day"
            value={ageOptions.find(
              (option) => option.value === dateOfBirth.day
            )}
            onChange={handleDayChange}
            options={dayOptions}
            placeholder="DD"
            required
            isSearchable={false}
          />

          <Select
            menuPlacement="auto"
            className="selectField"
            classNamePrefix="custom-select"
            id="year"
            value={ageOptions.find(
              (option) => option.value === dateOfBirth.year
            )}
            onChange={handleYearChange}
            options={ageOptions}
            placeholder="YYYY"
            required
            isSearchable={false}
          />
        </div>
        <div>
          <label htmlFor="country">SELECT LOCATION:</label>
          <Select
            menuPlacement="auto"
            className="selectFieldCountry"
            classNamePrefix="custom-select"
            id="country"
            value={selectedCountry}
            onChange={handleCountryChange}
            options={countryOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.code}
            placeholder="Rest Of World"
            // isClearable
            required
            isSearchable={false}
          />
        </div>
        {/* <div className='consentcheckField'>
          <label>
            <input type="checkbox" checked={consent} onChange={handleConsentChange} required />
            Glenfiddich can send me details of this experience later.
          </label>
        </div> */}
        <button type="submit">Enter Site</button>
      </form>
      <p className="tandcTxt">
        By entering this site you agree to our{" "}
        <a
          href="https://www.glenfiddich.com/en-us/general/terms-and-conditions/"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          Terms & Conditions
        </a>{" "}
        and{" "}
        <a
          href="https://www.glenfiddich.com//privacy-policy/"
          target="_blank"
          rel="noreferrer"
        >
          Privacy & Cookies Notice
        </a>
      </p>
      <p className="glentxt">
        *The Glenfiddich range has received more awards since 2000 than any
        other single malt Scotch whisky in two of the world’s most prestigious
        competitions, the International Wine & Spirit Competition and the
        International Spirits Challenge.
      </p>
      <p className="glentxt">
        *Glenfiddich Single Malt Scotch Whisky, ©2023 Imported by William Grant
        & Sons. New York, NY. Skillfully crafted, Enjoy responsibly.
      </p>
    </div>
  );
};
export default LandingPage;
