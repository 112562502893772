import React from "react";
import "./login-chatbot.scss";

interface Props {
  onGuestClick?: () => void;
  onMessengerClick?: () => void;
}

const LoginChatbot = ({
  onGuestClick,
  onMessengerClick,
}: Props): JSX.Element => {
  return (
    <div className="chatLoginScreen">
      <h2>Log in</h2>
      <div className="actionWrap">
        <button className="primaryBtn" onClick={onMessengerClick}>
          Chat in Messenger
        </button>
        <button className="primaryBtn" onClick={onGuestClick}>
          Continue as Guest
        </button>
      </div>
      {/* <p className="textcondition">
        By continuing as a guest you agree to Meta’s <a href="https://www.glenfiddich.com//general/terms-and-conditions/">Terms</a>.{" "}
        <a href="https://www.glenfiddich.com//privacy-policy/">Privacy Policy</a> and <a href="https://www.glenfiddich.com//privacy-policy/">Cookies Policy</a>.
      </p> */}
    </div>
  );
};

export default LoginChatbot;
