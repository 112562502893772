import React, { useState, useEffect } from "react";
import GlenfiddichLogo from "../../assets/images/logo/GlenfiddichLogo.png";
import chatprofilelogo from "../../assets/images/logo/chatprofilelogo.png";
import "./start-chat.scss";
import LoginChatbot from "../login-chatbot/login-chatbot";

interface Props {
  onStartChatClick: () => void;
  onGuestClick: () => void;
  onMessengerClick: () => void;
  showStartChat: boolean;
}

const StartChat = ({
  onStartChatClick,
  showStartChat,
  onGuestClick,
  onMessengerClick,
}: Props): JSX.Element => {
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);

  const handleStartChatClick = () => {
    onStartChatClick();
    setIsChatOpen(true);
  };

  useEffect(() => {
    setIsChatOpen(showStartChat);
  }, [showStartChat]);

  return (
    <div className={`startChatScreen ${isChatOpen ? "start-chat-open" : ""}`}>
      <div className="topSection">
        <div className="logoImageWrapper">
          <img src={GlenfiddichLogo} alt="GlenfiddichLogo" />
        </div>
        <h1>GLENFIDDICH GUIDE</h1>
      </div>
      <div className="stratchatMain">
        <div className="logowrap">
          <img src={chatprofilelogo} alt="chat-profile-logo" />
        </div>
        <h2>
          Chat With <span>Glenfiddich </span>
        </h2>
        <p className="welcometxt">
          Welcome! Try our new recommendation chatbot, Glenfiddich Guide.
        </p>
        <div className="action-wrap">
          <button className="primaryBtn" onClick={onGuestClick}>
            Start Chat
          </button>
        </div>
        <div className="footer">
          <p className="glentxt">
            *Glenfiddich Single Malt Scotch Whisky, ©2023 Imported by William
            Grant & Sons. New York, NY. Skillfully crafted, Enjoy responsibly.
          </p>
        </div>
      </div>
      {isChatOpen && ( // Show the LoginChatbot component based on isChatOpen state
        <LoginChatbot
          onGuestClick={onGuestClick}
          onMessengerClick={onMessengerClick}
        />
      )}
    </div>
  );
};

export default StartChat;
