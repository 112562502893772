import { ReactNode } from "react";
import { BiSolidUser } from "react-icons/bi";
const OutgoingMessage = ({
  isRecentMsg,
  children,
}: {
  isRecentMsg: boolean;
  children: ReactNode;
}) => {
  return (
    <div
      className={`chatMsg sender ${isRecentMsg ? "recentMsg" : "previousMsg"}`}
    >
      {children}
    <div className="msgTick">
        <BiSolidUser />
    </div>
    </div>
  );
};

export default OutgoingMessage;
