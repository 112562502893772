import "./typing.scss";

const Typing = () => {
  return (
    <div className="loader">
      <span className="loading__dot" />
      <span className="loading__dot" />
      <span className="loading__dot" />
    </div>
  );
};

export default Typing;
