export const welcome = [
  {
    question: "Hi, what is your name?",
    answers: {
      A: "",
      B: "",
      C: "",
    },
  },
  {
    question: "Nice to meet you ^name^ Please enter your email.",
    answers: {
      A: "",
      B: "",
      C: "",
    },
  },
  {
    question:
      " Hi, ^name^ Welcome to the Glenfiddich Guide. <br/> <br/> Our interactive guide provides you with a personalized Single Malt Scotch Whisky recommendation. <br/> <br/> Let's start with few questions.",
    answers: {
      A: "",
      B: "",
      C: "",
    },
  },
  {
    question: "What are you looking for today?",
    answers: {
      A: "Treating myself",
      B: "Gifting for someone",
      C: "Buying for an event",
    },
  },
];
export const journey1 = [
  {
    question: "What best describes your style?",
    answers: {
      A: "Minimal and modern",
      B: "Vibrant and refined",
      C: "Classic and smooth",
    },
  },
  {
    question: "How do you live your life?",
    answers: {
      A: "Indulge in the best",
      B: "Invest in quality",
      C: "Easily Adaptable",
    },
  },
  {
    question: "What flavor combination best suits your palate?",
    answers: {
      A: "Brioche & vanilla",
      B: "Banana, lime, ginger",
      C: "Toffee & caramel",
    },
  },
];
export const journey2 = [
  {
    question: "Who are you buying for?",
    answers: {
      A: "One who raised you",
      B: "Partner or family",
      C: "Friend or colleague",
    },
  },
  {
    question: "Which mantra best aligns with how they live their life?",
    answers: {
      A: "Indulge in the best",
      B: "Invest in quality",
      C: "Easily Adaptable",
    },
  },

  {
    question: "What flavor combination best suits their palate?",
    answers: {
      A: "Brioche & vanilla",
      B: "Banana, lime, ginger",
      C: "Toffee & caramel",
    },
  },
];
export const journey3 = [
  {
    question: "What is the vibe of the event?",
    answers: {
      A: "Lavish and opulent",
      B: "Lively and festive",
      C: "Smooth and casual",
    },
  },

  {
    question: "Which mantra best aligns with how the group lives their lives?",
    answers: {
      A: "Indulge in the best",
      B: "Invest in quality",
      C: "Easily Adaptable",
    },
  },
  {
    question: "What flavor combination best suits the group’s palate?",
    answers: {
      A: "Brioche & vanilla",
      B: "Banana, lime, ginger",
      C: "Toffee & caramel",
    },
  },
];

export const obj = {
  welcome: welcome,
  "Treating myself": journey1,
  "Gifting for someone": journey2,
  "Buying for an event": journey3,
};

const response1 = {
  A: "We recommend Glenfiddich Grand Cru - Aged 23 years and finished in French cuvée casks that impart flavorful aromas of decadence. <br/> <br/> Perfect for indulging in a dram at home.",
  B: "We recommend Glenfiddich Gran Reserva - Aged 21 years and finished in casks previously filled with a custom blend of golden rum. <br/> <br/> Perfect for imbibing with a dram at home.",
  C: "We recommend Glenfiddich Bourbon Barrel Reserve - Aged 14 years that embodies a bourbon heart with the soul of Single Malt Scotch Whisky. <br/> <br/> A versatile at-home spirit.",
  D: "We recommend our Signature Glenfiddich - Aged 12 years that pioneered the global Single Malt Scotch Whisky category. <br/> <br/> Enjoy “the best dram in the valley” at home.",
};

const response2 = {
  A: "We recommend gifting Glenfiddich Grand Cru - Aged 23 years and finished in French cuvée casks that impart decadent aromas. <br/> <br/> Make a delicious impression with this gift.",
  B: "We recommend gifting Glenfiddich Gran Reserva - Aged 21 years and finished in casks previously filled with a custom blend of golden rum. <br/> <br/> Gift the spectacular!",
  C: "We recommend gifting Glenfiddich Bourbon Barrel Reserve - Aged 14 years that embodies a bourbon heart with the soul of Single Malt Scotch Whisky.<br/> <br/>  Gift the spectacular!",
  D: `We recommend gifting our Signature Glenfiddich - Aged 12 years that pioneered the global Single Malt Scotch Whisky category.<br/> <br/> They'll enjoy "the best dram in the valley"`,
};

const response3 = {
  A: "We recommend Glenfiddich Grand Cru - Aged 23 years and finished in French cuvée casks that impart flavorful, decadent aromas. <br/><br/> Make a delicious impression.",
  B: "We recommend Glenfiddich Gran Reserva - Aged 21 years and finished in casks previously filled with a custom blend of golden rum.<br/><br/> Make a delicious impression.",
  C: "We recommend Glenfiddich Bourbon Barrel Reserve - Aged 14 years that embodies a bourbon heart with the soul of Single Malt Scotch Whisky.<br/><br/> Make a delicious impression.",
  D: "We recommend our Signature Glenfiddich - Aged 12 years that pioneered the global Single Malt Scotch Whisky category.<br/><br/>  Make a delicious impression.",
};

export const responses = {
  "Treating myself": response1,
  "Gifting for someone": response2,
  "Buying for an event": response3,
};
