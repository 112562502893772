import { ChangeEvent, FormEvent, useState } from "react";
import "./message.scss";
// import { BsFillPlusCircleFill } from "react-icons/bs";
// import { BsFillMicFill } from "react-icons/bs";
// import { BsEmojiSmile } from "react-icons/bs";
// import { FaCamera } from "react-icons/fa";
// import { FaRegImage } from "react-icons/fa";
// import { HiThumbUp } from "react-icons/hi";
import { IoSendSharp } from "react-icons/io5";

const Message = ({
  handleSubmit,
  index,
  isJourneySelected,
}: {
  handleSubmit: (e: string) => void;
  index: number;
  isJourneySelected: boolean;
}) => {
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
  });
  const [nameIsValid, setNameIsValid] = useState(true);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [name, setName] = useState("");
  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (isJourneySelected || index > 1) return;
    const text = index === 0 ? inputs.name.trim() : inputs.email.trim();

    handleSubmit(text);
    if (index === 0) {
      setInputs((prev) => ({ ...prev, name: "" }));
    } else {
      setInputs((prev) => ({ ...prev, email: "" }));
    }

    // Call the createGuest API with local storage data
    const token = localStorage.getItem("token");
    const birthdate = localStorage.getItem("birthdate");
    const country = localStorage.getItem("country");
    const userid = localStorage.getItem("userid");
    const payload = {
      userid,
      firstname: index === 0 ? inputs.name : name,
      lastname: "",
      emailid: inputs.email,
      country,
      birthdate,
      isguest: "true",
    };
    try {
      const backendapi = process.env.REACT_APP_BACKEND_API_URL;
      const response = await fetch(`${backendapi}createGuest`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        let name = "";
        if (index === 0) {
          name = inputs.name;
        } else {
          name = "";
        }
        setName(name);
        // Store the createuser value to the local storage
      } else {
        throw new Error("API request failed");
      }
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isJourneySelected || index > 2) return;

    // Update the input value
    if (index === 0) {
      setInputs((prev) => ({ ...prev, name: e.target.value }));

      // Validate the name as required
      const isValid = e.target.value.trim() !== "";
      setNameIsValid(isValid);
      setNameError(isValid ? "" : "Please enter your name");
    } else {
      setInputs((prev) => ({ ...prev, email: e.target.value }));

      // Validate the email
      const isValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        e.target.value
      );
      setEmailIsValid(isValid);
      setEmailError(isValid ? "" : "Please enter a valid email address");
    }
  };

  return (
    <div className="footer">
      {/* <BsFillPlusCircleFill className="footicon" />
      <FaCamera className="footicon" />
      <FaRegImage className="footicon" />
      <BsFillMicFill className="footicon" /> */}
      <form onSubmit={onSubmit} className="msgInputField">
        <input
          type="text"
          onChange={handleChange}
          value={index === 0 ? inputs.name : inputs.email}
          placeholder="Aa"
          disabled={isJourneySelected || index > 1}
          required={index === 0 || index === 1}
        />
        <button
          type="submit"
          disabled={
            (index === 0 && !nameIsValid) || (index === 1 && !emailIsValid)
          }
        >
          <IoSendSharp className="footicon foot-input-icon" />
        </button>
      </form>
      {index === 0 && nameError && <div className="error">{nameError}</div>}
      {index === 1 && emailError && <div className="error">{emailError}</div>}
      {/* <HiThumbUp className="footicon" /> */}
    </div>
  );
};

export default Message;
