const authenticateUser = async (): Promise<string | null> => {
    try {
      const backendapi = process.env.REACT_APP_BACKEND_API_URL;
      const response = await fetch(`${backendapi}authentication`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('API request failed');
      }
      const data = await response.json();
      const token = data.content.token;
      localStorage.setItem('token', token);
      return token;
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  
  export default authenticateUser;