import React from "react";
import GlenfiddichLogo from "../../assets/images/logo/GlenfiddichLogo.png";
import "./goback.scss";
interface Props {
  onGoBack: () => void;
}

const GoBack = ({ onGoBack }: Props): JSX.Element => (
  <div className="ageInvalidScreen">
    <div className="topSection">
      <div className="logoImageWrapper">
        <img src={GlenfiddichLogo} alt="GlenfiddichLogo" />
      </div>
      <h1>GLENFIDDICH GUIDE</h1>
    </div>
    <div className="invalidmsg">
      <h2>YOU ARE TOO YOUNG TO VISIT THIS WEBSITE.</h2>

      <button onClick={onGoBack}>Go back</button>
    </div>
    <div className="termstxt">
    <p>
       By entering this site you agree to our{" "}
        <a
          href="https://www.glenfiddich.com/en-us/general/terms-and-conditions/"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          Terms & Conditions
        </a>{" "}
        and{" "}
        <a
          href="https://www.glenfiddich.com//privacy-policy/"
          target="_blank"
          rel="noreferrer"
        >
          Privacy & Cookies Notice
        </a>
      </p>
      <p>
        *The Glenfiddich range has received more awards since 2000 than any
        other single malt Scotch whisky in two of the world’s most prestigious
        competitions, the International Wine & Spirit Competition and the
        International Spirits Challenge.
      </p>
      <p>
        *Glenfiddich Single Malt Scotch Whisky, ©2023 Imported by William Grant
        & Sons. New York, NY. Skillfully crafted, Enjoy responsibly.
      </p>
    </div>
  </div>
);

export default GoBack;
