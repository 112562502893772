import "./not-supported.scss";
import GlenfiddichLogo from "../../assets/images/logo/GlenfiddichLogo.png";

const NotSupported = () => {
  return (
    // <>App not supported in desktop</>
    <div className="notsupportScreen">
      <div className="container">
        <img src={GlenfiddichLogo} alt="Logo" />
        <div className="content">
          <p>"Hello there!! </p>
          <p>
            "For a smoother experience, try accessing the site on a mobile
            device."{" "}
          </p>
          <p>
            Thank you for your understanding and for choosing to browse with
            us."
          </p>
          <p className="tandcTxt">
       By entering this site you agree to our{" "}
        <a
          href="https://www.glenfiddich.com/en-us/general/terms-and-conditions/"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          Terms & Conditions
        </a>{" "}
        and{" "}
        <a
          href="https://www.glenfiddich.com//privacy-policy/"
          target="_blank"
          rel="noreferrer"
        >
          Privacy & Cookies Notice
        </a>
      </p>
      <p className="glentxt">
        *The Glenfiddich range has received more awards since 2000 than any
        other single malt Scotch whisky in two of the world’s most prestigious
        competitions, the International Wine & Spirit Competition and the
        International Spirits Challenge.
      </p>
      <p className="glentxt">
        *Glenfiddich Single Malt Scotch Whisky, ©2023 Imported by William Grant
        & Sons. New York, NY. Skillfully crafted, Enjoy responsibly.
      </p>
        </div>
      </div>
    </div>
  );
};

export default NotSupported;
