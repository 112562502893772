import { useState } from "react";
import {
  ChatBox,
  GoBack,
  LandingPage,
  StartChat,
  NotSupported,
} from "./components";
import useWindowSize from "./hooks/useWindowSize";

interface State {
  age: number | null;
  showChatbot: boolean;
  showChatBox: boolean;
  showStartChat: boolean;
}

const App = () => {
  const [state, setState] = useState<State>({
    age: null,
    showChatbot: false,
    showChatBox: false,
    showStartChat: false,
  });
  const size = useWindowSize();

  if (size.width > 430) {
    return <NotSupported />;
  }

  const handleRedirect = () => {
    const userid = localStorage.getItem("userid");
    const messengerUrl = process.env.REACT_APP_MESSENGER_REDIRECT_URL?.replace(
      "uniqueid",
      userid || ""
    );
    if (messengerUrl) {
      const messengerWindow = window.open(messengerUrl, "_blank");
      messengerWindow?.focus();
    } else {
      console.error(
        "REACT_APP_MESSENGER_REDIRECT_URL value not found or invalid"
      );
    }
  };
  const handleAgeSubmit = (age: number): void => {
    setState((prevState) => ({ ...prevState, age }));
  };

  const handleGoBack = (): void => {
    setState((prevState) => ({
      ...prevState,
      age: null,
      showChatbot: false,
      showChatBox: false,
      showStartChat: false,
    }));
  };

  const handleGuestClick = (): void => {
    setState((prevState) => ({
      ...prevState,
      showChatBox: true,
      showChatbot: false,
    }));
  };

  const handleStartChatClick = (): void => {
    setState((prevState) => ({
      ...prevState,
      showChatbot: true,
      showStartChat: true,
    }));
  };

  const { age, showChatbot, showChatBox, showStartChat } = state;
  if (age === null) {
    return <LandingPage onAgeSubmit={handleAgeSubmit} />;
  } else if (age < 21) {
    return <GoBack onGoBack={handleGoBack} />;
  } else if (age >= 21 && showChatbot) {
    return (
      <div>
        <StartChat
          onStartChatClick={() => {
            handleStartChatClick();
          }}
          showStartChat={showStartChat}
          onGuestClick={handleGuestClick}
          onMessengerClick={handleRedirect}
        />
      </div>
    );
  } else if (showChatBox) {
    return <ChatBox />;
  } else {
    return (
      <StartChat
        onStartChatClick={handleStartChatClick}
        showStartChat={showStartChat}
        onGuestClick={handleGuestClick}
        onMessengerClick={handleRedirect}
      />
    );
  }
};

export default App;
